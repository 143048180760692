import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

function IndexPage() {
  return (
    <Layout>
      <Seo title="A layer of indirection to Daniel Pehrson" />
      <a className="index" href="https://www.danielpehrson.com/" title="Daniel Pehrson's personal homepage">
        <blockquote className="quote" cite="https://en.wikipedia.org/wiki/Fundamental_theorem_of_software_engineering">
          <p>
            Any problem in computer science can be solved with another level of indirection
          </p>
          <footer>
            David J. Wheeler
          </footer>
        </blockquote>

        <blockquote className="counter-quote" cite="https://en.wikipedia.org/wiki/Fundamental_theorem_of_software_engineering">
          <p>
            <span>&hellip;except for the problem of too many levels of indirection</span>
          </p>
        </blockquote>
      </a>
    </Layout>
  )
}

export default IndexPage
